<template>
    <div>
      <div>
        <i :class="trigger.icon"></i> {{ trigger.label }}
        <span v-if="eventsLabel" class="keywords-span">( {{ eventsLabel }} )</span>
      </div>
    
      <b-modal id="date-modal" v-model="isShowModal" title="Samcart" size="lg">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Samcart Events</label>
              <multiselect
                v-model="automation.options.events"
                :options="eventList"
                :multiple="true"
                track-by="id"
                placeholder="Select Events"
                label="name"
              ></multiselect>
            </div>
          </div>
        </div>
    
        <template v-slot:modal-footer>
          <b-button variant="primary" @click="save">Save</b-button>
        </template>
      </b-modal>
    </div>
    </template>
    
    <script>
    import Vue from 'vue'
    import Multiselect from 'vue-multiselect'
    
    export default {
      components: {
        Multiselect
      },
    
      props: {
        automation: {
          type: Object,
          default: null,
        },
        blocks: {
          type: Array,
          default: null,
        },
        trigger: {
          type: Object,
          default: null,
        },
      },
    
      data() {
        return {
          isShowModal: false,
          eventList: [
            { id: 'order_completed', type: 'Order', name: 'Order Completed' },
            { id: 'product_added_to_order', type: 'AddedToOrder', name: 'Product Added to Order' },
            { id: 'product_purchased', type: 'Order', name: 'Product Purchased' },
            { id: 'product_refunded', type: 'Refund', name: 'Product Refunded' },
            { id: 'product_partially_refunded', type: 'PartialRefund', name: 'Product Partially Refunded' },
            { id: 'prospect_created', type: 'ProspectCreated', name: 'Prospect Created' },
            { id: 'subscription_canceled', type: 'Cancel', name: 'Subscription Canceled' },
            { id: 'subscription_charged', type: 'RecurringPaymentSucceeded', name: 'Subscription Charged' },
            { id: 'subscription_charge_failed', type: 'RecurringPaymentFailed', name: 'Subscription Charge Failed' },
            { id: 'subscription_delinquent', type: 'SubscriptionDelinquent', name: 'Subscription Delinquent' },
            { id: 'subscription_recovered', type: 'SubscriptionRestarted', name: 'Subscription Recovered' },
            { id: 'subscription_restarted', type: 'SubscriptionRestarted', name: 'Subscription Restarted' },
            { id: 'subscription_started', type: 'SubscriptionStarted', name: 'Subscription Started' },
            { id: 'subscription_charge_refunded', type: 'SubscriptionChargeRefunded', name: 'Subscription Charge Refunded' },
            { id: 'subscription_charge_partially_refunded', type: 'SubscriptionChargePartiallyRefunded', name: 'Subscription Charge Partially Refunded' },
            { id: 'upsell_purchased', type: 'UpsellPurchased', name: 'Upsell Purchased' },
          ],
          eventsSelected: [],
        }
      },
    
      computed: {
        eventsLabel() {
          if (this.eventsSelected)
            return this.eventsSelected.map(item => item.name).join(', ');
          return ''
        }
      },
    
      mounted() {
        this.$parent.$on('showModal', this.showModal)
        this.eventsSelected = this.automation.options.events
      },
    
      methods: {
        save() {
          if (!this.automation.options.events || !this.automation.options.events.length)
          {
            Vue.$toast.open({
              message: 'Keywords can\'t be empty',
              type: 'error',
              duration: 3000,
            })
            return false;
          }
          this.eventsSelected = this.automation.options.events
          this.isShowModal = false
        },
    
        showModal() {
          this.isShowModal = true
        },
      },
    }
    </script>
    
    <style lang="scss" scoped>
      .keywords-span {
        display: block;
        font-size: 13px;
        font-weight: normal;
      }
    </style>
    
    <style lang="scss">
    #date-modal .small-checkbox {
      .custom-checkbox {
        padding-left: 30px;
        margin-bottom: 5px;
      }
    
      .custom-control-label:before, .custom-control-label:after {
        width: 20px;
        height: 20px;
        left: -30px;
        top: 0px;
      }
    
      .custom-control-input {
        width: 20px;
        height: 20px;
        top: -4px;
      }
    }
    </style>