<template>
  <div>
    <div>
      <i :class="trigger.icon"></i> {{ trigger.label }}
      <span v-if="triggerLabel" class="trigger-span">( {{ triggerLabel }} )</span>
    </div>

    <b-modal id="email-reply-modal" v-model="isShowModal" title="Email Reply" size="lg">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Broadcast <span v-b-tooltip.hover class="cursor-pointer"
                title="List of broadcasts you want to track for."><i
                  class="uil uil-question-circle"></i></span></label>
            <b-spinner v-if="loadingBroadcasts" small></b-spinner>
            <multiselect v-else v-model="automation.broadcasts" :options="broadcastList" :multiple="true"
              track-by="id" placeholder="Select Broadcasts" label="name"></multiselect>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      broadcastList: [
      ],
      broadcastsSelected: [],
      loadingBroadcasts: false,
    }
  },

  computed: {
    triggerLabel() {
      if (this.broadcastsSelected)
        return this.broadcastsSelected.map(item => item.name).join(', ');
      return ''
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.broadcastsSelected = this.automation.broadcasts
  },

  methods: {
    save() {
      // if (!this.automation.broadcasts || !this.automation.broadcasts.length) {
      //   Vue.$toast.open({
      //     message: 'Broadcasts can\'t be empty',
      //     type: 'error',
      //     duration: 3000,
      //   })
      //   return false;
      // }
      this.broadcastsSelected = this.automation.broadcasts
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
      this.getBroadcasts()
    },

    getBroadcasts() {
      this.loadingBroadcasts = true
      let params = {
        per_page: 1000,
        provider: 'email',
      }
      this.$store.dispatch('broadcast/getAll', params)
        .then(res => {
          this.broadcastList = res.data.map(item => {
            return {
              id: item.id,
              name: item.name
            }
          })
          this.loadingBroadcasts = false
        })
        .catch(() => {
          this.loadingBroadcasts = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.trigger-span {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
</style>

<style lang="scss">
#email-reply-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>